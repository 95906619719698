
import Header from '../Header/Header';
import './HomePage.css'
import right_image from '../../images/right_image.png'
import heart from '../../images/heart.png'
import arrow3 from '../../images/arrow3.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

function HomePage(){

    const mobile = window.innerWidth <= 988 ? true : false;
    const trans = {type: 'spring', duration: 2}
    return(
        <div className='HomePage'>
            <div className='blur blur-homepage'>
            </div>
            <div className='left'>
                
                {/* Header = logo + header-menu */}
                <Header/>

                {/* the best club */}
                <div className='the-best-club'>
                    <motion.div className='motion'
                        initial= {{opacity: 0, left: mobile?'15rem':'25rem'}}
                        whileInView={{opacity: 1, left: mobile?'-0.4rem':'1rem'}}
                        transition={{...trans, type: 'tween'}}
                    ></motion.div>
                    <span>The best fitness club in the town</span>
                </div>

                {/* Shape your body text */}
                <div className='shape-body'> 
                    <div> {/* Div 1*/}
                        <span className='border-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div> {/* Div 2*/}
                        <span>Ideal Body</span>
                    </div>
                    <div> {/* Div 3*/}
                        <span>Join the Revolution to realize your fullest potential, your success is our goal. </span>
                        <span>Let's make it happen!!</span>
                    </div>
                </div>

                {/* figures & numbers */}
                <div className='figures'>
                    <div>{/* Div 1*/}
                        <span>
                            <NumberCounter end={10} start={0} delay='1' preFix="+"/>
                        </span>
                        <span>expert coachs</span>
                    </div>
                    <div>{/* Div 2*/}
                        <span>
                        <NumberCounter end={250} start={0} delay='1.3' preFix="+"/>
                        </span>
                        <span>members joined</span>
                    </div>
                    <div>{/* Div 3*/}
                        <span>
                        <NumberCounter end={20} start={0} delay='1' preFix="+"/>
                        </span>
                        <span>fitness programs</span>
                    </div>
                </div>

                {/* Buttons */}
                <div className='homepage-buttons'>
                    <button className='btn'>Let's Begin</button>
                    <button className='btn'>Learn More</button>
                </div>         
            </div>


            <div className='right'>
                <button className='btn'>Join Now</button>
                <div className='right_image'>
                    <img src = {right_image} alt=''/>
                </div>
                <div className='heart'>
                    <img src = {heart} alt=''/>
                    <span>Heart Rate</span>
                    <span>118 BPM</span>
                </div>

                <motion.img 
                initial={{right: mobile? '15rem' : '20rem'}}
                whileInView={{right: mobile? '20rem' : '30rem'}}
                transition={{trans}}
                src={arrow3} alt='' className='arrow3'/>
            </div>
        </div>
    );
}

export default HomePage;
