import { useRef } from 'react';
import './JoinUs.css';
import emailjs from '@emailjs/browser';

function JoinUs(){

    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_qubf9sc', 'template_ob2k8ug', form.current, 'MQzB5b319utSfpyKZ')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return(
        <div className='join' id='join-us'>
            <div className='left-j'>
                <hr />
                <div>
                    <span className='border-text'>READY TO</span>
                    <span> LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className='border-text'> WITH US?</span>
                </div>
            </div>
            <div className='right-j'>
                <form ref={form} className='email-container'>
                    <input type='email' name='user-email' placeholder='enter your mail address' />
                    <button className='btn btn-j' onClick={sendEmail}>Get a callback</button>
                </form>
            </div>
        </div>
    );
}

export default JoinUs