import './Programs.css'
import { programsData } from '../../Data/programsData';
import right_arrow from '../../images/right_arrow.png'

function Programs(){

    return(
        <div className="Programs" id="programs">
            {/* Programs Header */}
            <div className='blur programs-blur-1'></div>
            <div className='blur programs-blur-2'></div>
            <div className="programs-header">
                <span className="border-text">Explore Our</span>
                <span>Programs To</span>
                <span className="border-text">Shape you</span>
            </div>

            {/* data */}
            <div className='programs-categories'>
                {programsData.map((Program)=>(
                    <div className='categories'>
                        {Program.image}
                        <span>{Program.heading}</span>  
                        <span>{Program.details}</span>
                        <div className='join-now'>
                            <span>JOIN NOW</span>
                            <img className='right_arrow' src={right_arrow} alt=''/>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );

}
export default Programs;