import './Header.css'
import revolutionLogo from '../../images/revolutionLogo.PNG'
import menu from '../../images/menu.png'
import { useState } from 'react';
import {Link} from 'react-scroll';
function Header(){

    const mobile = window.innerWidth <= 988 ? true : false;  
    const [menuOpened, setMenuOpened] = useState(false);
    return(
        
        <div className='Header'>
            <img src={revolutionLogo} alt="" className='logo'/>   

            {
            menuOpened === false && mobile === true ? 
                (
                    <div>
                        <img src={menu} alt='none' onClick={()=>setMenuOpened(true)} />
                    </div>
                )
                
                :
                
                (
                <ul className='Header-Menu'>
                    <li onClick={()=>setMenuOpened(false)} >Home</li>    
                    <li>
                        <Link onClick={()=>setMenuOpened(false)} to = 'Programs'span={true}smooth={true}>Programs</Link>
                    </li>
                    <li>
                        <Link onClick={()=>setMenuOpened(false)} to = 'join-us'span={true}smooth={true}>Join</Link>
                    </li>
                </ul> 
                )
                
            }   
        </div>
    );
}

export default Header;